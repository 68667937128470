import { graphql } from "gatsby";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  PostTypeInProject,
  Template,
} from "../../../../config/wordPressConfig";
import { BlogPost } from "../../../interfaces/Blog";
import { useAllFundamentals } from "../../../queries/wpFundamentals/useAllFundamentals";
import { usePageForTemplate } from "../../../queries/wpPage/usePageForTemplate";
import { mapSingleWpPostToPost } from "../../../queries/wpPost/mapSingleWpPostToPost";
import RVORegisterTodayCtaSection from "../../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import RVOBlogTemplate from "../../04-templates/RVOBlogTemplate/RVOBlogTemplate";

export const GrundlagenPage = (): JSX.Element => {
  const blogs = usePageForTemplate(Template.KnowledgeDatabase);
  const { t } = useTranslation();

  const posts = useAllFundamentals();

  const fundamentalPosts: BlogPost[] = useMemo(
    () =>
      posts.map(
        (currentPostObject: GatsbyTypes.WpFundamentalsFieldsFragment) => {
          return mapSingleWpPostToPost(currentPostObject);
        }
      ),
    [posts]
  );

  return (
    <>
      <Helmet>
        <title>{t("wissen.header.fundamentals.title")}</title>

        <meta
          name="description"
          content={t("wissen.header.fundamentals.description")}
        />
      </Helmet>

      <RVOBlogTemplate
        blogPosts={fundamentalPosts}
        title={t("wissen.header.fundamentals.title")}
        text={t("wissen.header.fundamentals.description")}
        pageTitle="Grundlagen"
        pageId={blogs?.id as string}
        postType={PostTypeInProject.WpFundamental}
      />

      <RVORegisterTodayCtaSection />
    </>
  );
};

export default GrundlagenPage;

export const query = graphql`
  query GrundlagenPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
